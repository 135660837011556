<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <CreateProduct />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import CreateProduct from "../components/products/CreateProduct.vue";

export default {
  name: "AddProducts",

  components: {
    Navbar,
    LeftMenu,
    CreateProduct,
  },

  data: function() {
    return {};
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
};
</script>
