<template>
  <form>
    <div class="registry-sign-up-form-content">
      <div class="company-info-wrapper">
        <div class="container-fluid p-0">
          <div class="info-top">
            <h6>
              Add Product Information
            </h6>
            <router-link class="custom-btn4" to="/products">
              <span>Back</span>
            </router-link>
          </div>

          <div class="row">
            <div class="col-12 col-md-4 col-lg-2 px-0 pr-md-2">
              <div class="user-profile-image">
                <div class="profile-image">
                  <div id="preview" class="profile-image">
                    <img v-if="url" :src="url" />
                    <img
                      v-else
                      src="../../assets/images/product-image.png"
                      alt=""
                    />
                  </div>
                </div>

                <div class="change-profile-image" @click="openinput()">
                  <form>
                    <input
                      type="file"
                      id="vue-file-upload-input-profile-photo"
                      class="d-none"
                      @change="onFileChange"
                      accept="image/png, image/gif, image/jpeg"
                    />

                    <div class="change-icon">
                      <span class="text">Add Product</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-8 col-lg-10 p-0">
              <div class="row">
                <div class="col-12 col-md-6 px-0 pr-md-2">
                  <div class="form-group">
                    <label for="customer_name"
                      >Product Name <span class="astrick">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="product_name"
                      placeholder="Enter Product Name"
                      autocomplete="off"
                      v-model.trim="$v.details.product_name.$model"
                      :class="{
                        'is-invalid': $v.details.product_name.$error,
                        'is-valid': !$v.details.product_name.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.product_name.required"
                        >Product Name is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 px-0">
                  <div class="form-group">
                    <label for="contact_person"
                      >Product Code<span class="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      v-model.trim="$v.details.product_code.$model"
                      class="form-control"
                      id="product_code"
                      placeholder="Enter Product Code"
                      :class="{
                        'is-invalid': $v.details.product_code.$error,
                        'is-valid': !$v.details.product_code.$invalid,
                      }"
                      autocomplete="off"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.product_code.required"
                        >Product Code is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 px-0 pr-md-2">
                  <div class="form-group">
                    <label for="contact_person"
                      >Product Category<span class="astrick">*</span>
                    </label>
                    <v-select
                      v-model.trim="$v.details.category_id.$model"
                      :options="categoryOptions"
                      :placeholder="categoryPlaceholder"
                      aria-required="true"
                      class="form-control p-0 pl-2"
                      :class="{
                        'is-invalid': $v.details.category_id.$error,
                        'is-valid': !$v.details.category_id.$invalid,
                      }"
                      autocomplete="off"
                    ></v-select>
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.category_id.required"
                        >Product Category is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 px-0">
                  <div class="form-group">
                    <label for="product_cost"
                      >Product Cost ({{ this.$currency }})<span class="astrick"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model.trim="$v.details.product_cost.$model"
                      class="form-control"
                      id="product_cost"
                      placeholder="Enter Product Cost"
                      :class="{
                        'is-invalid': $v.details.product_cost.$error,
                        'is-valid': !$v.details.product_cost.$invalid,
                      }"
                      autocomplete="off"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.product_cost.required"
                        >Product Cost is required</span
                      >
                      <span v-if="!$v.details.product_cost.numeric"
                        >Product Cost should be integer</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 px-0">
              <div class="form-group">
                <label for="contact_person"
                  >Product Price ({{ this.$currency }})<span class="astrick"
                    >*</span
                  >
                </label>
                <input
                  type="text"
                  v-model.trim="$v.details.product_price.$model"
                  class="form-control"
                  id="product_price"
                  placeholder="Enter Product Price"
                  :class="{
                    'is-invalid': $v.details.product_price.$error,
                    'is-valid': !$v.details.product_price.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.product_price.required"
                    >Product Price is required</span
                  >
                  <span v-if="!$v.details.product_price.numeric"
                    >Product Price should be integer</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 px-0 px-md-2">
              <div class="form-group">
                <label for="contact_person"
                  >Product Quantity<span class="astrick">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="$v.details.product_quantity.$model"
                  class="form-control"
                  id="product_quantity"
                  placeholder="Enter Product Quantity"
                  :class="{
                    'is-invalid': $v.details.product_quantity.$error,
                    'is-valid': !$v.details.product_quantity.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.product_quantity.required"
                    >Product Quantity is required</span
                  >
                  <span v-if="!$v.details.product_quantity.numeric"
                    >Product Quantity should be integer</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 p-0">
              <div class="form-group">
                <label for="contact_person"
                  >Product Unit<span class="astrick">*</span></label
                >
                <input
                  type="text"
                  v-model.trim="$v.details.product_unit.$model"
                  class="form-control"
                  id="product_unit"
                  placeholder="Select Product Unit"
                  autocomplete="off"
                  :class="{
                    'is-invalid': $v.details.product_unit.$error,
                    'is-valid': !$v.details.product_unit.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.product_unit.required"
                    >Unit is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 p-0">
              <div class="form-group">
                <label for="contact_person">Note</label>
                <textarea
                  v-model.trim="details.product_note"
                  class="form-control"
                  id="product_note"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <div class="col-12 px-0">
              <div class="form-group d-flex">
                <input
                  type="checkbox"
                  class="mr-2"
                  v-model="details.status"
                  id="productActive"
                />
                <label class="form-check-label" for="productActive"
                  >This product is active</label
                >
              </div>
            </div>
            <button
              type="button"
              class="custom-btn2 float-right"
              @click="CreateProduct(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Create Product</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, integer, numeric } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CreateProduct",
  data: function() {
    return {
      details: {
        product_name: "",
        product_code: "",
        category_id: "",
        product_cost: "",
        product_price: "",
        product_quantity: "",
        product_unit: "",
        product_note: "",
        status: false,
      },
      categoryOptions: [],
      categoryPlaceholder: "Type Category or Code",
      taxOptions: ["Exclusive", "Inclusive"],
      taxPlaceholder: "Select Tax Type",
      isLoadingArray: [],
      url: null,
      productImage: null,
      oldPicture: true,
    };
  },
  validations: {
    details: {
      product_name: {
        required,
      },
      product_code: {
        required,
      },
      category_id: {
        required,
      },
      product_cost: {
        required,
        integer,
      },
      product_price: {
        required,
        numeric,
      },
      product_quantity: {
        required,
        numeric,
      },
      product_unit: {
        required,
      },
    },
  },
  methods: {
    onFileChange(e) {
      this.oldPicture = false;
      this.productImage = e.target.files[0];
      this.url = URL.createObjectURL(this.productImage);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    getCategory() {
      axios
        .get(`${process.env.VUE_APP_API_URL}category/getCategory`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.categoryOptions = [];
            response.data.data.forEach((element) => {
              this.categoryOptions.push(
                element.category_code + " - " + element.category_name
              );
            });
          }
        });
    },
    CreateProduct: function(index) {
      var formData = new FormData();
      formData.append("product_name", this.details.product_name);
      formData.append("product_code", this.details.product_code);
      formData.append("category_id", this.details.category_id);
      formData.append("product_cost", this.details.product_cost);
      formData.append("product_price", this.details.product_price);
      formData.append("product_quantity", this.details.product_quantity);
      formData.append("product_unit", this.details.product_unit);
      formData.append(
        "product_note",
        this.details.product_note == null ? "" : this.details.product_note
      );
      formData.append("picture", this.productImage);
      formData.append("status", this.details.status == true ? 1 : 2);

      if (this.details.category_id == "") {
        alert("Product Category is requried.");
      }
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      axios
        .post(process.env.VUE_APP_API_URL + "product/create", formData)
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$router.push({ name: "Products" });
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.getCategory();
  },
};
</script>

<style></style>
